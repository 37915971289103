<template>
  <div>
    <v-row no-gutters class="d-flex justify-space-between py-4 pr-6">
      <!-- Navigation -->

      <div class="d-flex flex-row align-center pl-4 pl-md-16 my-4">
        <v-icon color="primary" size="16" v-text="'arrow_back'" />
        <a @click="$router.push({ name: `${entity}-tickets` })">
          <span class="ml-1 font-weight-bold">{{ $t('activity.editActivity.navigateBack') }}</span>
          <span v-text="$t('organization.sections.tickets')" />
        </a>
      </div>
    </v-row>
    <v-container class="px-4 px-md-10 pt-0 pb-6">
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <div class="d-flex mb-2" cols="12">
            <h2 class="mr-4" v-text="guildMatch.name" />
            <v-chip
              v-if="guildMatch.status === 'published'"
              outlined label color="success" style="min-width: 5rem;"
            >
              {{ $t('common.published') }}
            </v-chip>
            <v-chip
              v-else
              outlined label color="pending" style="min-width: 5rem;"
            >
              {{ $t('common.pending') }}
            </v-chip>
          </div>
          <div class="d-flex mb-4">
            <v-icon class="mr-4" color="primary" v-text="'calendar_today'" />
            <span v-text="formatDate(guildMatch.date.start)" />
          </div>
          <div class="d-flex mb-4">
            <v-icon class="mr-4" color="primary" v-text="'lightbulb_outline'" />
            <span v-text="$t('cluster.tickets.matchDetail.disclaimer')" />
          </div>
          <div class="mb-8">
            <h3 class="mb-4" v-text="$t('cluster.tickets.matchDetail.howManyTickets')" />
            <v-row v-if="guildMatch.status === 'pending'">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.number="form.ticketCount" :label="$t('cluster.tickets.ticketCount')"
                  outlined dense type="number" min="1" :error-messages="getFieldErrors(`ticketCount`)"
                  @keypress="validateKeyNumeric($event)"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  class="w-100 w-sm-auto" color="primary" height="41" dark depressed @click="enableAndPublish()"
                  v-text="$t('cluster.tickets.matchDetail.enableAndPublish')"
                />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="guildMatch.ticketCount" :label="$t('cluster.tickets.matchDetail.ticketsEnabled')"
                  outlined dense disabled
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="guildMatch.assignedTicketCount" :label="$t('cluster.tickets.matchDetail.ticketsAssigned')"
                  outlined dense disabled
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="guildMatch.available" :label="$t('cluster.tickets.matchDetail.ticketsAvailable')"
                  outlined dense disabled
                />
              </v-col>
            </v-row>
          </div>
          <div>
            <div class="d-flex justify-end">
              <v-text-field
                v-model="searchText"
                class="flex-md-grow-0" :label="$t('common.search')" prepend-inner-icon="search" outlined dense
              />
            </div>
            <v-data-table
              v-if="guilds.length"
              :headers="headers" :items="guilds" :search="searchText" dense
              class="mt-6 table--has-pointer" @click:row="item => selectRow(item)"
            />
            <empty-list v-else :content="$t('organization.sections.tickets')" />
          </div>
          <assign-tickets-dialog
            v-if="showAssignTicketsDialog"
            :organization-id="organizationId" :cluster="cluster" :guild="selectedGuild" :guild-match="guildMatch" @closeDialog="showAssignTicketsDialog = false"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import dateFilter from '@/utils/mixins/dateFilter'
import i18n from '@/plugins/i18n'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'MatchDetail',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
    AssignTicketsDialog: () => import('@/modules/guildMatches/AssignTicketsDialog'),
  },
  mixins: [dateFilter, formUtils],
  props: { organizationId: { type: String, required: true }, clusterId: { type: String, default: null }, guildMatchId: { type: String, required: true } },
  data: () => ({ form: { ticketCount: null }, searchText: null, showAssignTicketsDialog: false, selectedGuild: null }),
  validations: { form: { ticketCount: { required } } },
  computed: {
    ...mapGetters('guildMatches', ['guildMatches', 'activities']),
    ...mapGetters({ rawProjects: 'organization/projects' }),
    ...mapGetters('organization', ['clusters']),
    ...mapGetters({ cluster: 'cluster/data' }),
    headers: () => [
      { text: i18n.t('guild.type.guild'), value: 'name' },
      { text: i18n.t('cluster.tickets.assignedTicketCount'), value: 'assignedTicketCount' },
      { text: i18n.t('cluster.tickets.requested'), value: 'requested' },
      { text: i18n.t('cluster.tickets.accepted'), value: 'accepted' },
      { text: i18n.t('cluster.tickets.purchased'), value: 'purchased' },

    ],
    entity: ({ clusterId }) => clusterId ? 'cluster' : 'organization',
    guildMatch: ({ guildMatches, guildMatchId }) => {
      const item = guildMatches.find(gM => gM.id === guildMatchId)
      const status = item.ticketCount > 0 ? 'published' : 'pending'
      const available = item.ticketCount - item.assignedTicketCount
      return { ...item, status, available }
    },
    guilds: ({ rawProjects, guildMatch, activities }) => rawProjects.filter(project => project.type === 'guild' && project.clusterId === guildMatch.clusterId)
      .map(project => {
        const name = project.name
        const activity = activities.find(a => a.id === `${project.id}-${guildMatch.id}`)
        const assignedTicketCount = activity?.ticketCount ?? 0
        const ticketEnrollmentCountByStatus = activity?.ticketEnrollmentCountByStatus
        const requested = ticketEnrollmentCountByStatus?.pendingApproval
          ? (ticketEnrollmentCountByStatus?.pendingApproval + ticketEnrollmentCountByStatus?.pendingPayment + ticketEnrollmentCountByStatus?.complete) : 0
        const accepted = ticketEnrollmentCountByStatus?.pendingPayment
          ? (ticketEnrollmentCountByStatus?.pendingPayment + ticketEnrollmentCountByStatus?.complete) : 0
        const purchased = ticketEnrollmentCountByStatus?.complete ?? 0
        return { id: project.id, name, assignedTicketCount, requested, accepted, purchased }
      }),
  },
  async created() {
    this.$store.commit('loader/show')
    const { organizationId, guildMatch: { id: guildMatchId, clusterId } } = this
    if (this.activities[0]?.guildMatchId !== guildMatchId) {
      await this.$store.dispatch('guildMatches/bindActivities', { organizationId, guildMatchId })
    }
    await this.$store.dispatch('cluster/bind', { organizationId, clusterId })
    if (!this.$store.getters['cluster/managers'].length) await this.$store.dispatch('cluster/bindManagers', { organizationId, clusterId })
    this.$store.commit('loader/hide')
  },
  methods: {
    async enableAndPublish() {
      if (!this.isFormValid()) return
      const { organizationId, form: { ticketCount }, guildMatch, cluster } = this
      const data = { ticketCount }
      await this.runAsync(() => this.$store.dispatch('guildMatches/enableAndPublish', { organizationId, guildMatch, cluster, data, ticketCount }))
    },
    selectRow(item) {
      this.selectedGuild = item
      this.showAssignTicketsDialog = true
    },
  },
}
</script>
